//.navbar {
//    position: relative;
//    min-height: 50px;
//    margin-bottom: 20px;
//    border: 1px solid transparent;
//}
/* ------------------------------------------------------------
    Nav
    ------------------------------------------------------------ */
.navbar-default {
    top: 0;
    width: 100%;
    z-index: 100;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.navbar-default.loggedout {
    margin-bottom: 40px;
}

@media (min-width: 992px) {
    .navbar-default {
        height: 75px;
    }
}

.navbar-default .navbar-header {
    margin: 0;
}

.navbar-default .navbar-header .navbar-brand {
    padding: 0;
    position: relative;
    width: 187px;
    height: 81px;
    overflow: hidden;
    background: url("../images/logos/activate_logo_blue_blue.png") no-repeat center center/100%;
}

@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .navbar-default .navbar-header .navbar-brand {
        /* on retina, use image that's scaled by 2 */
        background-image: url("../images/logos/activate_logo_blue_blue.png");
        background-size: 100% 100%;
    }
}

@media (min-width: 992px) {
    .navbar-default .navbar-header .navbar-brand {
        width: 187px;
        height: 81px;
    }
}

.navbar-default .navbar-header .nav-icon {
    margin: 13px 0px 0 0;
    width: 30px;
    height: 21px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.navbar-default .navbar-header .nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.navbar-default .navbar-header .nav-icon span:nth-child(1) {
    top: 0px;
}

.navbar-default .navbar-header .nav-icon span:nth-child(2) {
    top: 9px;
}

.navbar-default .navbar-header .nav-icon span:nth-child(3) {
    top: 18px;
}

.navbar-default .navbar-header .nav-icon span:nth-child(1), .navbar-default .navbar-header .nav-icon span:nth-child(2), .navbar-default .navbar-header .nav-icon span:nth-child(3) {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navbar-default .navbar-header .nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

.navbar-default .navbar-header .nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.navbar-default .navbar-header .nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 18px;
    left: 8px;
}

.navbar-default .navbar-toggle {
    padding: 9px 10px;
    margin: 8px -9px 8px 0;
    border: 0;
    border-radius: 0;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.navbar-default .navbar-collapse {
    border: 0;
}

.navbar-default .navbar-nav {
    margin: 0px -15px;
}

.navbar-default .navbar-nav > li > a {
    padding: 15px 15px;
    font-size: 14px;
    //text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    //border-top: 2px solid transparent;
}

@media (min-width: 992px) {
    .navbar-default .navbar-nav li a {
        padding: 25px 10px 24px 10px;
    }
}

@media (min-width: 1200px) {
    .navbar-default .navbar-nav li a {
        padding: 25px 13px 24px 13px;
    }
}

.navbar-default .navbar-nav li a .fa {
    font-size: 11px;
    vertical-align: 2px;
    color: #2dc8ff;
}

.navbar-default .navbar-nav li a:hover, .navbar-default .navbar-nav li:focus a {
    border-color: #58E79F;
}

.navbar-default .navbar-nav li a.btn {
    margin: 10px 15px 10px 15px;
    padding: 10px 25px;
    font-size: 14px;
    //border-top: 1px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

@media (min-width: 992px) {
    .navbar-default .navbar-nav li a.btn {
        margin-right: 0;
        margin-left: 15px;
    }
}

@media (min-width: 1200px) {
    .navbar-default .navbar-nav li a.btn {
        margin-left: 30px;
    }
}

.navbar-default .navbar-nav li a.btn-success {
    color: #fff;
    border-width: 2px;
}

.navbar-default .navbar-nav li a.btn-success:hover, .navbar-default .navbar-nav li a.btn-success:focus {
    background-color: #58E79F;
    color: #fff;
    border-color: #2be185;
}

//.navbar-default .navbar-nav li a.btn-default {
//  border-top: 1px solid var(--primary); }
.navbar-default .navbar-nav li a.btn-default:hover, .navbar-default .navbar-nav li a.btn-default:focus {
    border-color: var(--primary);
}

.navbar-default .navbar-nav li.active a {
    border-color: #58E79F;
}

.navbar-default .navbar-nav li.active:hover a, .navbar-default .navbar-nav li.active:focus a {
    border-color: #58E79F;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    border-color: #58E79F;
}